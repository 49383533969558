<template>
    <van-tabbar v-model="active" :safe-area-inset-bottom="true" @change="clk">
        <van-tabbar-item
            v-for="v in tabbar_arr"
            icon-prefix="al-icon"
            :icon="v.icon | filter_icon"
            :key="v.name"
            >{{ v.name }}</van-tabbar-item
        >
    </van-tabbar>
</template>

<script>
export default {
    name:'TabbarComponent',
    data(){
        return {
            active: this.$store.state.active,
        }
    },
    computed:{},
    methods:{
        clk(a) {
            this.$emit('clk',a)
        }
    },
    filters:{
        filter_icon(v) {
            const i = v.split('al-icon-')
            return i[1]
        }
    },
    props:{
        tabbar_arr: Array,
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style lang='less'></style>